<template>
  <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>


<script>
  import SectionTabs from "@/components/Section/SectionTabs.vue";
  export default {
    components: { SectionTabs },
    data() {
      return {
        title: "Inicio",
        tab_component: [
          {
            name: "Dashboard",
            route: "inicio-dash",
          },
          // {
          //   name: "Personalizar",
          //   route: "interfaz-admin",
          // },
        ],
      };
    },
    mounted() {
      setInterval(() => {
        this.checkIfUserIsLogged();
      }, 100);
    },
    methods: {
      goTo(route) {
        var current_route = this.$route.name;
        if (route == current_route) {
          return;
        } else {
          this.$router.push({ name: route });
        }
      },
      activeTab(tab) {
        var current_route = this.$route.name;
        if (tab == current_route) {
          return "active";
        }
      },
    },
  };
</script>
